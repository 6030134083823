<template>
  <div class="clients page">
    <div class="content">
      <div class="page_header">
        <h1>Клиенты</h1>
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="create">Клиент</el-button>
      </div>
      <div id="filter">
        <div class="search_query">
          <el-input
              v-model="filter.query"
              clearable
              size="mini"
              @keyup.enter.native="changeFilter"
              @change="changeFilter"
              placeholder="Поиск контактам, имени, названию организации"
          ></el-input>
        </div>
      </div>
      <div class="box">
        <div class="grid" v-loading="loading">
          <div
              class="request grid_row"
              v-for="client in clients"
              :key="client.id">
            <el-row :gutter="0">
              <el-col :xs="15" :sm="4" :offset="0">
                <div class="client">
                  <el-link
                      @click.stop.prevent
                      :underline="false"
                      class="client_link"
                      icon="el-icon-user">
                    <!-- <el-avatar size="small"> {{client.name[0] }} </el-avatar> -->
                    <span class="client_name">{{ client.name }}</span>
                  </el-link>
                </div>
              </el-col>
              <el-col :xs="9" :sm="3" :offset="0">
                <div class="contacts">
                  <template v-if="client.contacts.length">
                    <div
                        class="contact"
                        v-for="contact in client.contacts"
                        :key="contact.id">
                      <template class="" v-if="contact.type == 'phone'">
                        <el-link
                            :href="'tel:+7' + contact.formatted"
                            class="phone_link"
                            icon="el-icon-phone"
                            :underline="false">{{ contact.value }}
                        </el-link>
                      </template>
                      <template class="" v-if="contact.type == 'email'">
                        <el-link
                            :href="'mailto:' + contact.value"
                            class="email_link"
                            icon="el-icon-message"
                            :underline="false">{{ contact.value }}
                        </el-link>
                      </template>
                    </div>
                  </template>
                  <div class="contact empty" v-else>Нет контактов</div>
                </div>
              </el-col>
              <el-col :xs="15" :sm="4" :offset="0">
                 <div class="requests" v-if="client.requests">
                  <el-tag
                      size="mini"
                      type="info"
                      class="calculation"
                      v-for="r in client.requests"
                      :key="r.id"
                      @click="openRequest(r.id)"
                  >
                    <span class="title">Обращение №{{ r.id }}</span> -
                    <span class="time">
                      <i class="el-icon-time"></i> {{ moment(r.created_at).format("DD.MM.YYYY HH:mm") }}
                    </span>
                  </el-tag>
                </div>
                <div class="contact empty" v-else>Нет отбращений</div>
              </el-col>
              <el-col :xs="15" :sm="4" :offset="0">
                <div class="calculations" v-if="client.calculations && client.calculations.length">
                  <el-tag
                      size="mini"
                      type="info"
                      class="calculation"
                      v-for="c in client.calculations"
                      :key="c.id"
                      :class="{ notapplicable: Boolean(!Number(c.applicable)) }"
                      @click="openRequest(c.request_id)"
                  >
                    <span class="title">Расчет №{{ c.number }}</span> -
                    <strong class="price">{{ (c.sum_wd) | price }}р</strong>
                  </el-tag>
                </div>
                <div class="contact empty" v-else>Нет расчетов</div>
              </el-col>
              <el-col :xs="15" :sm="4" :offset="0">
                <div class="orders" v-if="client.orders">
                  <el-tag
                      size="mini"
                      class="order"
                      v-for="o in client.orders"
                      :key="o.id"
                      :class="{ notapplicable: !o.applicable }"
                      @click="openOrder(o.id)"
                     >
                    <span class="title">Заказ №{{ o.number }}</span>
                    -
                    <strong class="price">{{ (o.sum_wd) | price }}р</strong>
                  </el-tag>
                </div>
                <div class="contact empty" v-else>Нет заказов</div>
              </el-col>
              <el-col :xs="15" :sm="3" :offset="0">
                <div class="payments" v-if="client.payments">
                  <el-tag
                      size="mini"
                      type="success"
                      class="payment"
                      v-for="p in client.payments"
                      :key="p.id"
                      :class="{ notapplicable: !p.applicable }"
                      @click="openPayment(p.id)"
                  >
                    <span class="title">Платеж №{{ p.number }}</span>
                    -
                    <strong class="price">{{ (p.sum) | price }}р</strong>
                  </el-tag>
                </div>
                <div class="contact empty" v-else>Нет платежей</div>
              </el-col>
              <el-col :span="2" style="text-align: right">
                <el-button type="primary" icon="el-icon-view" size="mini"></el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
            layout="prev, pager, next"
            :total="clientsSize"
            :page-size="filter.limit"
            :current-page="currentPage"
            :hide-on-single-page="true"
            @current-change="paginateChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as clientsActions} from "@/store/modules/clients";

import price from "@/filters/price";

export default {
  name: "Clients",
  data() {
    return {
      filter: {
        limit: this.$route.query.limit ? Number(this.$route.query.limit) : 50,
        offset: this.$route.query.offset ? Number(this.$route.query.offset) : 0,
        query: this.$route.query.query ? this.$route.query.query : '',
      }
    }
  },
  computed: {
    ...mapState({
      clients: state => state.clients.list,
      loading: state => state.clients.loading,
      clientsSize: state => state.clients.size,
      submitting: state => state.clients.submitting,
    }),
    currentPage() {
      return ((this.filter.offset + this.filter.limit) / this.filter.limit)
    },
  },
  methods: {
    create() {

    },
    loadClients() {
      this.$store.dispatch(clientsActions.get, this.filter)
    },
    paginateChange(page) {
      this.filter.offset = (page - 1) * this.filter.limit;
      this.changeRoute();
      this.loadClients();
    },
    changeFilter() {
      this.filter.offset = 0
      this.changeRoute()
      this.loadClients()
    },
    changeRoute() {
      this.$router
          .push({path: 'clients', query: this.filter})
          .catch((e) => {
            console.log('route not changed')
          })
    },
    openClient(id) {
      this.$router.push({path: 'clients/'+id})
    },
    openRequest(id){
      this.$router.push({path: 'requests/'+id})
    },
    openOrder(id){
      this.$router.push({path: 'orders/'+id})
    },
    openPayment(id){
      this.$router.push({path: 'payments/'+id})
    },
    clear() {
      this.$store.dispatch(clientsActions.clear)
    }
  },
  filters: {
    price
  },
  mounted() {
    this.loadClients()
  },
  beforeDestroy() {
    this.clear()
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
